//global bindings
const Ajax = (() => {
    const buildQueryStringUrl = (url, data) => {
        if (!data) {
            return url;
        }

        const keys = Object.keys(data).filter(x => typeof data[x] !== "undefined");

        if (!keys.length) {
            return url;
        }

        const query = new URLSearchParams();
        keys.forEach(x => query.append(x, data[x]));
        return url.indexOf('?') !== -1 ? `${url}&${query}` : `${url}?${query}`;
    };

    const performGet = function (url) {
        let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
        let responseType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
        const settings = {
            method: "GET",
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        };

        if (responseType === "json") {
            settings.headers["Content-Type"] = "application/json";
        }

        return fetch(buildQueryStringUrl(url, data), settings);
    };

    const performGetJson = function (url) {
        let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
        return performGet(url, data, "json").then(response => response.json());
    };

    const performGetText = function (url) {
        let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
        return performGet(url, data, "text").then(response => {
            if (response.headers.has("Content-Type") && response.headers.get("Content-Type").indexOf("application/json") === 0) {
                return response.json().then(model => Promise.reject(model));
            }

            return response.ok ? response.text() : Promise.reject({
                status: response.status,
                body: response.statusText
            });
        });
    };

    const performSubmission = function (url, data) {
        let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "POST";
        const settings = {
            method: method,
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        };

        if (url instanceof HTMLFormElement) {
            const form = url;
            url = form.action;
            const formData = new FormData(form);

            if (typeof data === "object") {
                Object.keys(data).forEach(key => {
                    formData.set(key, data[key]);
                });
            }
            data = formData;
        }

        if (data instanceof FormData) {
            settings.body = data;
        } else {
            settings.body = JSON.stringify(data);
            settings.headers["Content-Type"] = "application/json";
        }

        return fetch(url, settings).then(response => {
            if (response.headers.has("Content-Type") && response.headers.get("Content-Type").indexOf("application/json") === 0) {
                return response.json().then(model => {
                    if (typeof model === "object" && typeof model.status === "number" && (model.status < 200 || model.status >= 300)) {
                        return Promise.reject(model);
                    }

                    return Promise.resolve(model);
                });
            }

            return response.ok ? Promise.resolve(response) : Promise.reject({
                status: response.status,
                body: response.statusText
            });
        });
    };

    return {
        getJson: performGetJson,
        getText: performGetText,
        post: (url, data) => performSubmission(url, data, "POST"),
        put: (url, data) => performSubmission(url, data, "PUT"),
        delete: (url, data) => performSubmission(url, data, "DELETE")
    };
})();

const renderPartial = (container, content, {showSpinner = true, clearArea = true} = {}) => {
    container = typeof container === "string" ? document.querySelector(container) : container;

    if (!container) {
        return Promise.reject("Container does not exist");
    }

    if (clearArea) {
        container.innerHTML = "";
    }
    // if (showSpinner) {
    //     Spinner.showFor(container);
    // }

    return Promise.resolve(content)
        .then(loadedContent => {
                // if (showSpinner) {
                //     Spinner.hide();
                // }

                if (typeof loadedContent === "string") {
                    container.innerHTML = loadedContent;
                } else {
                    container.innerHTML = "";
                    container.appendChild(loadedContent);
                }

                ControlBinders.run(container);

                return container;
            },
            (err) => {
                // if (showSpinner) {
                //     Spinner.hide();
                // }

                return Promise.reject(err);
            });
}

const AjaxForm = function (form, {
    url,
    validate = true,
    onSubmiting  = null,
    onSuccess,
    onFailure = Notification.showFetchError,
    onReset,
    responseType = "json"
} = {}) {
    if (typeof form === "string") {
        form = document.querySelector(form);
    }

    if (typeof url === "undefined") {
        url = form.getAttribute("action");
    }

    this.form = form;

    this.reset = () => {
        AjaxForm.resetForm(form);

        if (typeof onReset === "function") {
            onReset();
        }
    };

    let isSubmitting = false;

    const markFormAsSubmitting = (state) => {
        isSubmitting = state;
        form.querySelectorAll("[type='submit']").forEach(elm => {
            elm.disabled = state;
        });
    };

    this.isSubmitting = () => isSubmitting;
    this.submit = () => {
        const afterValidation = () => {
            markFormAsSubmitting(true);
            const data = AjaxForm.getFormData(form);

            const finish = () => {
                if (responseType === "text") {
                    Ajax.postGetText(url, data)
                        .finally(() => markFormAsSubmitting(false))
                        .then(onSuccess, onFailure);
                } else {
                    Ajax.postGetJson(url, data)
                        .finally(() => markFormAsSubmitting(false))
                        .then(onSuccess, onFailure);
                }
            };

            const wrapRecaptcha = () => {
                if (typeof RecaptchaPromiseWrapper === "undefined" || RecaptchaPromiseWrapper.isUsedOnForm(this.form) === false) {
                    return finish();
                }

                return RecaptchaPromiseWrapper.invoke(this.form, data)
                    .then(success => {
                        if (success) {
                            finish();
                        } else {
                            markFormAsSubmitting(false);
                        }
                    });
            };

            if (typeof onSubmiting === "function") {
                Promise.resolve(onSubmiting(data))
                    .then(cancel => {
                        if (cancel === false) {
                            markFormAsSubmitting(false);
                        } else {
                            wrapRecaptcha();
                        }
                    });
            } else {
                wrapRecaptcha();
            }
        };
        if (validate === true) {
            if (form.valid()) {
                afterValidation();
            }
        } else if (typeof validate === "function") {
            Promise.resolve(validate())
                .then(valid => {
                    if (valid === true) {
                        afterValidation();
                    }
                });
        } else {
            afterValidation();
        }
    };
    this.toJson = () => AjaxForm.formToJson(form);

    form.addEventListener("submit",
        (evt) => {
            evt.preventDefault();

            if (!isSubmitting) {
                this.submit();
            }
        });

    form.querySelectorAll("button[type='reset']").forEach(elm => {
        elm.addEventListener("click",
            () => {
                event.preventDefault();
                this.reset();
            });
    });
};
AjaxForm.resetForm = form => {
    form.reset();

    Array.from(form.querySelectorAll("[data-form-resetable]"))
        .concat(Array.from(form.elements))
        .forEach(elm => {
            if (typeof elm.reset === "function") {
                elm.reset(elm.dataset.resetValue);
            }
        });
};
AjaxForm.getFormData = form => {
    form = typeof form === "string" ? document.getElementById(form) : form;

    const data = new FormData(form);

    form.querySelectorAll("[data-dropzone-wrap]").forEach(elm => {
        const fileWrapper = elm.fileWrapper;

        if (!fileWrapper) {
            return;
        }

        const files = fileWrapper.getFiles();
        const paramName = fileWrapper.getParamName();

        for (let i = 0; i < files.length; i++) {
            data.append(paramName, files[i], files[i].name);
        }
    });

    return data;
};
AjaxForm.formToJson = form => {
    form = typeof form === "string" ? document.getElementById(form) : form;

    const model = {};

    form.querySelectorAll("[name]").forEach(elm => {
        if (elm.type === "radio" || elm.type === "checkbox") {
            if (elm.type === "checkbox" &&
                typeof model[elm.getAttribute("name")] === "undefined" &&
                form.querySelectorAll(`[name='${elm.getAttribute("name")}']`).length > 1) {
                model[elm.getAttribute("name")] = [];
            }

            if (!elm.checked) {
                return;
            }

            if (Array.isArray(model[elm.getAttribute("name")])) {
                model[elm.getAttribute("name")].push(elm.value);
                return;
            }
        }

        model[elm.getAttribute("name")] = elm.value;
    });

    return model;
};

if (typeof RecaptchaPromiseWrapper !== "undefined" && typeof ControlBinders !== "undefined") {
    ControlBinders.register(".g-recaptcha", RecaptchaPromiseWrapper.setup);
}

AjaxForm.formElementsJSON = (elements) => {
    const getValue = element => {
        if (element.type === "checkbox") {
            return element.checked;
        }

        if (element.type === "file") {
            return element.dataset.filevalue;
        }

        return element.value;
    }

    const data = {};

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        if (!element.name) {
            continue;
        }

        const value = getValue(element);
        const sections = element.name.split('.');

        let progress = data;

        for (let sectionI = 0; sectionI < sections.length; sectionI++) {
            let propName = sections[sectionI];
            const isLastSection = sectionI >= (sections.length - 1);

            const openIndexBracket = propName.indexOf('[');
            const closeIndexBracket = propName.indexOf(']');

            if (openIndexBracket !== -1 && closeIndexBracket !== -1) {
                const arrayPropertyName = propName.substring(0, openIndexBracket);
                const arrayIndex = parseInt(propName.substring(openIndexBracket + 1, closeIndexBracket));

                if (typeof progress[arrayPropertyName] === "undefined") {
                    progress[arrayPropertyName] = [];
                }

                progress = progress[arrayPropertyName];
                propName = arrayIndex;
            }

            if (isLastSection) {
                if (typeof progress[propName] === "undefined" ||
                    (element.type === 'checkbox' && typeof propName[propName] !== "boolean")) {
                    progress[propName] = value;
                }

                break;
            }

            if (typeof progress[propName] === "undefined") {
                progress[propName] = {};
            }

            progress = progress[propName];
        }
    }

    return data;
}